import Accordion from 'react-bootstrap/Accordion';
import Image from 'react-bootstrap/Image';
import '../App.css'

function FAQ() {
  return (
    <section id='faq'>

    <Accordion defaultActiveKey="0" className='service-cont'>
      <div className='faq-title'>
        <h4>Frequently Asked Questions</h4>
      </div>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Question: Where is Northern Dental Located?</Accordion.Header>
        <Accordion.Body>
        <Image src={'location.png'} alt="Dr Image" thumbnail rounded/>
        Northern Dental, 358 Hamilton River Rd, Unit 5, Happy Valley Goose Bay - A0P 1C0
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Question: Do I need an appointment?</Accordion.Header>
        <Accordion.Body>
        Yes. However, we accept walk ins when we can, and we update that information on our Facebook page. Here’s the link <a href="https://www.facebook.com/profile.php?id=100063615431804">
        <i class="fa-brands fa-facebook"/></a> to get the most updated information. Or simply call the reception at 709-896-9668.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Question: How to contact Northern Dental?</Accordion.Header>
        <Accordion.Body>
        Phone No.: 709-896-9668
        <br />
        Email: northerndental@bellaliant.com
        <br />
        Facebook: <a href="https://www.facebook.com/profile.php?id=100063615431804">
        <i class="fa-brands fa-facebook"/></a>
        <br />
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>Question: Does Northern Dental accept insurance? </Accordion.Header>
        <Accordion.Body>
        We accept all major insurances. We do direct billings to most insurance companies and MCP. We are working towards getting Northern Dental register for the CDCP (The Canadian Dental Care Plan) as well. Follow our Facebook page for latest updates.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>Question: Is Dr. Garg good with children?</Accordion.Header>
        <Accordion.Body>
        Dr. Garg is one of the best you will find around, to handle patients of all ages, especially children. His gentle and calm demeanour helps ease anxiety in no time. Additionally, he is qualified to administer Nitrous Oxide, and oral sedation for patients of all ages. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>Question: How does Northern Dental help maintain good oral health?</Accordion.Header>
        <Accordion.Body>
        Dr. Garg listens to all your concerns, examines your teeth and creates a customized plan for you to experience excellent oral health and enjoy a beautiful smile all the time. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>Question: Do you provide Dental Hygiene services?</Accordion.Header>
        <Accordion.Body>
        Yes. Our Dental Hygienist is always available to take care of your oral health.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>Question: What kind of technology we use at Northern Dental?</Accordion.Header>
        <Accordion.Body>
        Northern Dental is equipped with modern digital X-rays, including PAN and CEPH X-rays. In addition, we use Nitrous Oxide Sedation. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>Question: What our Goose Bay patients say about us?</Accordion.Header>
        <Accordion.Body>
        We have 5 Star Google Reviews!
        <Image src={'Test3.png'} alt="Dr Image" thumbnail rounded/>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>
    </section>
  );
}

export default FAQ;