import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';

export default function App() {
  return (
    <section id='footer'>
    <MDBFooter bgColor='light' className='text-center text-lg-left fixed-bottom'>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>

        <small>&copy; {new Date().getFullYear()} Copyright{' '}</small>
        <small className='text-dark'>
        | Dr. Amandeep Garg Dentistry Professional Corp.
        </small>

      </div>
    </MDBFooter>
    </section>
  );
}