import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from './Components/Navbar';
import Testimonials from './Components/Testimonials';
import About from './Components/About';
import Services from './Components/Services';
import Contact from './Components/Contact';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';
import Consult from './Components/Consult';

function App() {
  return (
  <div className='App'>
    <header>
      <Navbar/>
    </header>
    <div className='body'>
      <Consult/>
      <About/>
      <Services/>
      <Testimonials/>
      <Contact/>
      <FAQ/>
    </div>
    <footer>
      <Footer/>
    </footer>

   </div>
  );
}

export default App;
