import React from 'react';
import './About.css';
import { Card } from 'react-bootstrap';

function About() {
  return (
    <div id="about">
    <div className="about-container">
          <div className="text-container">
            <h1>About Dr. Garg</h1>
            <p>
            Dr. Amandeep Garg at the Northern Dental completed his Bachelor of Dentistry in India in 2001. He had his own practice in India till the year 2012 and then he immigrated to Canada. 
            Dr. Garg directly challenged and passed all the qualifying exams for practicing dentistry in Canada, and obtained his licence to practice dentistry in Canada in 2016. 
            He worked in Kingston, ON before moving to Newfoundland and Labrador in the August 2016, and has been working as a General Dentist with Mount Pearl Dental since then. He purchased the practice at Northern Dental in early 2024.
            Dr. Garg is highly passionate about his work. He has 22 years of experience in General Dentistry, and is trained to administer nitrous oxide for conscious sedation during dental treatment. Apart from providing regular dental services to his patients with care, he is highly skilled at doing root canal treatments, specialized extractions, some orthodontics (traditional braces).
            Dr. Garg lives with his wife and 2 kids. In his free time, Dr. Garg likes to read, and play chess.
           </p>
          </div>
          <div className="image-container">
            <img src="garg.png" alt="About Us" className="circular-image" />
          </div>
    </div>

    <div className="about-container2">
        <Card className='quote'>
        <Card.Header>Compassion and Care</Card.Header>
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p>
              {' '}
              Dr. Garg and his team is dedicated to your comfort and well being. We take the time to listen, and address your concerns, and focus on creating a personalized care plan that meets your needs and respects your pace at the same time.{' '}
            </p>
            {/* <footer className="blockquote-footer">
              Someone famous in <cite title="Source Title">Source Title</cite>
            </footer> */}
          </blockquote>
        </Card.Body>
      </Card>

      <Card className='quote'>
        <Card.Header>Philosophy</Card.Header>
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p>
              {' '}
              We understand that visiting the dentist can be source of anxiety not just for children but for many adults as well. Dr. Amandeep Garg at Northern Dental is committed to provide a calm and stress-free experience for all his patients.{' '}
            </p>
            {/* <footer className="blockquote-footer">
              Someone famous in <cite title="Source Title">Source Title</cite>
            </footer> */}
          </blockquote>
        </Card.Body>
      </Card>

      <Card className='quote'>
        <Card.Header>Expertise and Technology</Card.Header>
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p>
              {' '}
              Dr. Garg is qualified for administering Nitrous Oxide Sedation.
              <ul>
                <li>
                Nitrous Oxide (Laughing Gas) Sedation: A safe, mild sedative inhaled through a mask.
                </li>
                <li>
                Oral Sedation: We can prescribe a Medication taken by mouth before your appointment.
                </li>
              </ul>{' '}
            </p>
            {/* <footer className="blockquote-footer">
              Someone famous in <cite title="Source Title">Source Title</cite>
            </footer> */}
          </blockquote>
        </Card.Body>
      </Card>
    </div>
        
  </div>
  );
}

export default About;