import React from 'react';
import './Services.css';

const data = [
  { id: 1, image: 'root.webp', title: 'Root Canal Treatment', description: 'Tooth pain can be debilitating. Our surgical root canal treatments are designed to save your natural teeth and relieve pain quickly and Effectively.' },
  { id: 2, image: 'ext.jpeg', title: 'Tooth Extractions', description: 'When necessary, we perform extractions with care and precision, ensuring a smooth and comfortable recovery. Tooth extractions may be warranted due to impaction, crowding, infection or decay.' },
  { id: 3, image: 'Dentistry.png', title: 'Restorative Services', description: 'We address cavities and damage with high-quality materials and techniques, restoring your teeth to their natural function and appearance.' },
  { id: 4, image: 'Hygiene.png', title: 'Preventive Hygiene', description: 'Our check-ups include thorough examinations, professional cleanings, and personalized advice to help you prevent dental issues.' },
  { id: 5, image: 'mouth.jpg', title: 'Mouthgaurds', description: 'Custom-fitted mouthguards protect your teeth during sports or from teeth grinding, preventing damage and discomfort.' },
  { id: 6, image: 'invisalign.png', title: 'Invisalign', description: 'Clear, custom aligners for a discreet, comfortable, and effective teeth-straightening solution.' },
  { id: 7, image: 'pediatric.jpg', title: 'Emergency Care', description: 'We offer Same – day appointments for urgent dental needs' },
  { id: 8, image: 'Ortho.png', title: 'Ortho Consult', description: 'Orthodontic consultations for personalized treatment plans to straighten your teeth.' },
];

const App = () => {
  return (
    <div id="services" className="container">
      <h1 className="title">Services at Northern Dental</h1>
      <div className="grid-container">
        {data.map(item => (
          <div className="card" key={item.id}>
            <img src={item.image} alt={item.title} className="card-image" />
            <h2 className="card-title">{item.title}</h2>
            <p className="card-description">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
