import React from 'react'
import Swal from 'sweetalert2'

const ContactForm = () => {

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "19a98405-129e-438a-b4aa-29ad71e80549");
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
    
        if (res.success) {
            Swal.fire({
                title: "Success!",
                text: "Message sent successfully!",
                icon: "success"
              });
        }
    };
    return(
        <section className='contact'>
            <form className="contact-form" onSubmit={onSubmit}>
                <h2>Contact Form</h2>

                <div className='input-box'>
                    <label>Full Name:
                    <input type="text" className='field' placeholder='Enter Your Name' name='name' required/>
                    </label>
                    
                </div>

                <div className='input-box'>
                    <label>Phone Number:
                    <input type="text" className='field' placeholder='Enter Your Ph. Number' name='name' required/>
                    </label>
                    
                </div>

                <div className='input-box'>
                    <label>Email Address:
                    <input type="email" className='field' placeholder='Enter Your Email' name='email' required/>
                    </label>
                    
                </div>

                <div className='input-box'>
                    <label>Your Message:
                    <textarea name="message" className='field-message' placeholder='Enter your Query' required />
                    </label>
                   
                </div>
                <button type="submit">Send Message!</button>
            </form>
        </section>
    )
}

export default ContactForm;