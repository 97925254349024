import React from 'react';
import ContactForm from './ContactForm';

const Contact = () => {
    return (
            <section className='contact-section'>
                <ContactForm />

                <div className="contact-container">
                 <h2>Contact Us</h2>
                    <div className="contact-info">
                     <div className="contact-item">
                         <i className="fas fa-map-marker-alt"></i>
                         <p>358 Hamilton River Rd.</p>
                         <p>Unit 5, Happy Valley - Goose Bay</p>
                         <p>NL, A0P 1C0</p>
                     </div>
                    <div>
                    <br/>
                    <a href="https://www.facebook.com/profile.php?id=100063615431804">
                    <i class="fa-brands fa-facebook"/></a>
                    </div>
                    <div>
                    <br/>
                    <i class="fa fa-envelope"/> northerndental@bellaliant.com
                    </div>
                    <div>
                    <br/>
                    <i class="fa fa-phone"/> (709) 896-9668
                    </div>
                    <div className="contact-item">
                    <br/>
                        <i className="fas fa-clock"></i>
                        <p>Mon-Fri: 8:30am - 5:30pm</p>
                        <p>Saturday: Open based on appointments</p>
                        <p>Sunday: Closed</p>
                    </div>
                    </div>
                </div>
            </section>

    );
};

export default Contact;