import "./Consult.css"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-scroll';

function Consult() {
  return (
    <section className="hero">
        <div className="content">
            <Card className="consult-card">
                <Card.Title className="consult-card-title">Consult with us</Card.Title>
                <Card.Subtitle className="consult-card-subtitle">
                  <i class="fa fa-phone"> 709 896 9668 </i>
                </Card.Subtitle>
                <Card.Body className="consult-card-body">

                We offer the best dental care services.
                <br />
                Consult with our experts and book your appointment today!

                </Card.Body>

                <Button className="consult-card-button" variant="dark">
                <Link 
                to="contact-section" 
                activeClass='custom-active'
                spy={true}
                smooth={true} 
                offset={50} 
                duration={500}>Book Now!</Link>
                </Button>
                
            </Card>
        </div>
    </section>
  );
}

export default Consult;