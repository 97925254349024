import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-scroll';
import './Navbar.css'; 
import { NavLink } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';

function NavbarComponent() {
  return (
    <div className='container'>
      <Navbar expand="lg" fixed="top"  className="custom-navbar">
      <Container fluid>
        <Navbar.Brand href="/">
           <h2 id='custom-brand'>Northern Dental</h2>
          <h5>Happy Valley - Goose Bay</h5>
        </Navbar.Brand>

        <Navbar className='contact-links'>

        <Dropdown as={NavItem}>
        <Nav.Link href='https://www.facebook.com/profile.php?id=100063615431804'>
        <i class="fa-brands fa-facebook"/>
        </Nav.Link> 
        </Dropdown> 
        
         <Dropdown as={NavItem}>
         <Dropdown.Toggle as={NavLink}>
         <i class="fa fa-envelope"/>
         </Dropdown.Toggle>
          <Dropdown.Menu>
           <Dropdown.Item>
           northerndental@bellaliant.com
           </Dropdown.Item>
          </Dropdown.Menu>
         </Dropdown>
          
         <Dropdown as={NavItem}>
         <Dropdown.Toggle as={NavLink}>
         <i class="fa fa-phone"/>
         </Dropdown.Toggle>
          <Dropdown.Menu>
           <Dropdown.Item>
           (709) 896-9668
           </Dropdown.Item>
          </Dropdown.Menu>
         </Dropdown> 
        </Navbar>


        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav
            className="my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll>

           <Nav.Link>
            <Link 
                to="hero" 
                activeClass='custom-active'
                spy={true}
                smooth={true} 
                offset={10}
                duration={100}>Home</Link></Nav.Link>
            <Nav.Link>
            <Link 
                to="about" 
                activeClass='custom-active'
                spy={true}
                smooth={true} 
                offset={-80} 
                duration={100}>About</Link></Nav.Link>
            <Nav.Link>
            <Link 
                to="services" 
                activeClass='custom-active'
                spy={true}
                smooth={true} 
                offset={-80} 
                duration={200}>Services</Link></Nav.Link>
            <Nav.Link>
            <Link 
                to="testimonials" 
                activeClass='custom-active'
                spy={true}
                smooth={true} 
                offset={50} 
                duration={200}>Testimonials</Link></Nav.Link>
            <Nav.Link>
            <Link 
                to="contact-section" 
                activeClass='custom-active'
                spy={true}
                smooth={true} 
                offset={0} 
                duration={100}>Contact</Link></Nav.Link>
            <Nav.Link>
            <Link 
                to="faq" 
                activeClass='custom-active'
                spy={true}
                smooth={true} 
                offset={0} 
                duration={300}>FAQs</Link></Nav.Link>
          </Nav>
        </Navbar.Collapse>

      </Container>
      </Navbar>
    </div>
  );
}

export default NavbarComponent;